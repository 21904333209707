import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { DOLTypographyProps } from '@doltech/ui/lib/figma/DesignSystemV2/Typography';
import { SharedFooter } from '@doltech/ui/lib/figma/Layout/Landing/Footer/SharedFooter';
import {
  LandingLayout,
  LandingLayoutContent,
} from '@doltech/ui/lib/figma/Layout/Landing/LandingLayout';
import { LandingSideBar } from '@doltech/ui/lib/figma/Layout/Landing/LandingSidebar/LandingSidebar';
import { LandingHeaderFullWidth } from '@doltech/ui/lib/figma/Layout/Landing/PremadeHeader/LandingHeaderFullWidth';
import {
  deviceBreakpoints,
  SHARED_TABLET_CONTENT_WIDTH,
} from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import * as React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  .one-column-content {
    padding: 24px 16px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }

  b {
    font-weight: 600;
  }
  ul {
    padding-left: 26px;
    li {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h1 {
    font-family: noto-serif, serif;

    &.font-inter {
      font-family: Inter, sans-serif;
    }
  }
  a {
    color: ${colorsV2.blue100};

    &:hover {
      text-decoration: underline;
    }
  }
  .empty-block {
    height: 100px;
  }

  @media ${deviceBreakpoints.fromDesktop} {
    .one-column-content {
      padding: 24px 0;
    }
    .section-middle-content {
      margin: 0 auto;
      max-width: ${SHARED_TABLET_CONTENT_WIDTH}px;
    }
  }
`;

interface OneColumnVariants {
  h1: DOLTypographyProps;
  h2: DOLTypographyProps;
  h3: DOLTypographyProps;
  paragraph: DOLTypographyProps;
}

export const oneColumnVariants: OneColumnVariants = {
  h1: {
    variant: 'regular/32',
    tabletVariant: 'regular/48-60',
  },
  h2: {
    variant: 'semi-bold/32',
  },
  h3: {
    variant: 'semi-bold/20',
  },
  paragraph: {
    variant: 'regular/16',
  },
};

export const OneColumnStaticPage = ({ children }) => {
  return (
    <Main>
      <LandingLayout defaultSidebarOpen={false}>
        <LandingHeaderFullWidth />

        <LandingLayoutContent>
          <LandingLayoutContent.LeftOverlaySidebar displayAsSideBar>
            <LandingSideBar disableHeadTag />
          </LandingLayoutContent.LeftOverlaySidebar>

          <LandingLayoutContent.SectionMiddle>
            <div className="section-middle-content">
              <div className="one-column-content">
                {children}
                <div className="empty-block" />
              </div>
            </div>
          </LandingLayoutContent.SectionMiddle>
        </LandingLayoutContent>
      </LandingLayout>

      <SharedFooter />
    </Main>
  );
};
