import cl from 'classnames';
import * as React from 'react';

import { ResponsiveTypography, ResponsiveTypographyProps } from './v3/ResponsiveTypography';

interface TypoProps
  extends Omit<ResponsiveTypographyProps, 'children' | 'style'>,
    React.HTMLAttributes<HTMLDivElement> {
  color?: any;
  block?: boolean;
  onClick?: () => void;
  text?: string;
  className?: string;
  parentClassName?: string;
}

const Text = (props: TypoProps, ref) => {
  const { parentClassName, className, block, text, ...rest } = props;
  if (text) {
    return (
      <ResponsiveTypography.Text
        className={cl(parentClassName, className)}
        ellipsis={{ tooltip: text }}
        {...rest}
      >
        {text}
      </ResponsiveTypography.Text>
    );
  }

  if (block) {
    return <ResponsiveTypography.Paragraph className={cl(parentClassName, className)} {...rest} />;
  }
  return <ResponsiveTypography.Text className={cl(parentClassName, className)} {...rest} />;
};

export const AttentionText = (props) => (
  <Text parentClassName="attention-text" variant="bold/56-91" {...props} />
);

export const PageHeaderText = (props) => (
  <Text parentClassName="page-header-text" variant="bold/32-52" {...props} />
);

export const SectionHeaderText = (props) => (
  <Text parentClassName="section-header-text" variant="medium/18-29" {...props} />
);

export const ReadingText = (props) => (
  <Text parentClassName="reading-text" variant="medium/16-23" {...props} />
);

export const SubText = (props) => (
  <Text parentClassName="sub-text" variant="regular/12-20" {...props} />
);

export const SubTextV2 = (props) => (
  <Text parentClassName="sub-text" variant="regular/14-20" {...props} />
);

export const LabelText = (props) => (
  <Text parentClassName="label-text" variant="regular/12-20" {...props} />
);

export const LinkNormalText = (props) => (
  <Text parentClassName="link-normal-text" variant="regular/16-23" {...props} />
);
