import { useAppScrollRef } from '@doltech/core/lib/appScroll/withAppScroll';
import { routingUrls } from '@doltech/core/lib/routing/routing.config';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { Typography } from '@doltech/ui/lib/figma/DesignSystemV2/Typography';
import {
  withLandingSharedLayout,
  withLandingSharedLayoutStaticProps,
} from '@doltech/ui/lib/figma/Layout/Landing/hocs/withLandingSharedLayout';
import { Link } from '@doltech/ui/lib/figma/Link';
import { fetchPopularResult } from '@doltech/ui/lib/figma/SearchEngine/api/search-api';
import { ExploreDolContent } from '@doltech/ui/lib/figma/SearchEngine/ExploreDolContent/ExploreDolContent';
import { SeoHead } from '@doltech/ui/lib/figma/SeoHead/SeoHead';
import { deviceBreakpoints } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { isBrowser } from '@doltech/utils/lib/platform';
import { smoothScroll } from '@doltech/utils/lib/smooth-scroll';
import { getLandingUrlFromPath } from '@doltech/utils/lib/url';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useEffectOnce } from 'react-use';
import styled from 'styled-components';

import { OneColumnStaticPage } from '../templates/OneColumnStaticPage';

/** Search Section **/
const PopularSearchesContainer = styled.div`
  .popular-search-section {
    margin-top: 24px;
    background-color: ${colorsV2.white100};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .popular-search-item {
      display: grid;
      padding: 8px 16px;
      grid-column-gap: 8px;
      grid-auto-flow: column;
      grid-auto-columns: min-content auto;

      .popular-search-item-label {
        border-radius: 50%;
        background-color: ${colorsV2.gray10};
        justify-content: center;
        align-items: center;
        display: flex;
        width: 32px;
        height: 32px;
      }

      .popular-search-item-title {
        display: flex;
        align-items: center;
        a > span {
          color: ${colorsV2.black100};
        }
      }

      :hover {
        .popular-search-item-label {
          background-color: ${colorsV2.primary100};
          color: ${colorsV2.white100};
        }
        .popular-search-item-title {
          a > span {
            color: ${colorsV2.primary100};
          }
        }
      }
    }
  }
  margin-bottom: 24px;

  @media ${deviceBreakpoints.fromTablet} {
    margin-bottom: 56px;
  }
`;
const PopularSearches = () => {
  const { data } = useQuery<any>(['GET_SEARCH_POPULAR_RESULT'], () => fetchPopularResult(), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <PopularSearchesContainer>
      <Typography.Text variant="regular/16" style={{ color: colorsV2.black70 }}>
        Bạn hãy thử những từ khóa sau
      </Typography.Text>
      <div className="popular-search-section">
        {data?.data?.map((item, index) => (
          <div key={index} className="popular-search-item">
            <div className="popular-search-item-label">{index + 1}</div>
            <div className="popular-search-item-title">
              <Link
                href={{
                  pathname: routingUrls.landing.SEARCH_PATH,
                  query: { search: item.title, id: item.title },
                }}
                passHref
              >
                <Typography.Text variant="regular/14">{item.title}</Typography.Text>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </PopularSearchesContainer>
  );
};

/** Explore DOL content **/

const NotFoundPageContainer = styled.div`
  .one-column-content {
    margin: 0 auto;
  }
  .not-found-text {
    padding: 60px 0;
    text-align: center;
  }
`;
const NotFoundPage = (props) => {
  const { ...rest } = props;
  const { appScrollRef } = useAppScrollRef();
  useEffectOnce(() => {
    if (isBrowser()) {
      setTimeout(() => {
        const scrollContainer = appScrollRef.current;
        smoothScroll(300, scrollContainer, 0);
      }, 5);
    }
  });
  return (
    <>
      <SeoHead
        title="Not Found - DOL IELTS Đình Lực | tuhocielts.dolenglish.vn"
        canonical={getLandingUrlFromPath('/404')}
        openGraph={{
          url: getLandingUrlFromPath('/404'),
          title: 'Not Found - DOL IELTS Đình Lực | tuhocielts.dolenglish.vn',
        }}
      />

      <NotFoundPageContainer>
        <OneColumnStaticPage {...rest}>
          <div className="not-found-text">
            <Typography.Text
              desktopVariant="semi-bold/32"
              tabletVariant="semi-bold/32"
              variant="semi-bold/24"
              style={{ color: colorsV2.gray60 }}
            >
              Không tìm thấy trang
            </Typography.Text>
          </div>
          <PopularSearches />
          <ExploreDolContent />
        </OneColumnStaticPage>
      </NotFoundPageContainer>
    </>
  );
};

export default withLandingSharedLayout(NotFoundPage);

export const getStaticProps = withLandingSharedLayoutStaticProps();
