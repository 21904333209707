const values = {
  FULL_TEST: 'Online Test',
  PRACTICE_PASSAGE: 'Reading Practice',
  PRACTICE_SECTION: 'Listening Practice',
  LINEAR_THINKING: 'Linearthinking',
  DICTATION: 'Chép Chính Tả',
  create_trending_success_title: 'Create Succes',
  create_trending_success_message: 'Create Trending Success',
  create_trending_error_title: 'Create Trending Error',
  LANDING_FULL_TEST: 'Online Test',
  LANDING_READING_PRACTICE_TEST: 'Reading Practice',
  LANDING_LISTENING_PRACTICE_TEST: 'Listening Practice',
  LANDING_DICTATION: 'Chép Chính Tả',
  READING_IELTS_BLOG: 'IELTS Reading',
  LISTENING_IELTS_BLOG: 'IELTS Listening',
  CENTER_REVIEW_IELTS_BLOG: 'Center Review',
  COURSE_REVIEW_IELTS_BLOG: 'Course Review',
  TEACHER_REVIEW_IELTS_BLOG: 'Teacher Review',
  BOOK_REVIEW_IELTS_BLOG: 'Book Review',
  BOOK_REVIEW: 'Book Review',
  CENTER_REVIEW: 'Center Review',
  COURSE_REVIEW: 'Course Review',
  TEACHER_REVIEW: 'Teacher Review',
  IELTS_INFO_BLOG: 'Tổng quan về IELTS',
  IELTS_INFO: 'Tổng quan về IELTS',
  LANDING_IELTS_KNOWLEDGE: 'Kiến thức IELTS',

  LANDING_READING_IELTS_BLOG: 'IELTS Reading',
  LANDING_LISTENING_IELTS_BLOG: 'IELTS Listening',
  LANDING_SPEAKING_IELTS_BLOG: 'IELTS Speaking',
  LANDING_WRITING_IELTS_BLOG: 'IELTS Writing',

  LANDING_WRITING_SAMPLE: 'IELTS Writing Sample',
  LANDING_WRITING_SAMPLE_T1: 'Writing Task 1 Academic',
  LANDING_WRITING_SAMPLE_T2A: 'Writing Task 2 Academic',
  LANDING_WRITING_SAMPLE_T2G: 'Writing Task 1 General',
  LANDING_SPEAKING_SAMPLE: 'IELTS Speaking Sample',
  LANDING_SPEAKING_SAMPLE_P1: 'Speaking Sample Part 1',
  LANDING_SPEAKING_SAMPLE_P2: 'Speaking Sample Part 2',
  LANDING_SPEAKING_SAMPLE_P3: 'Speaking Sample Part 3',
  LANDING_CENTER_REVIEW: 'Đánh giá trung tâm',
  LANDING_IELTS_REVIEW: 'Tổng quan về IELTS',
  LANDING_DAILY_LEARNING_NEWS: 'Daily Learning',
  DAILY_LEARNING_NEWS: 'Daily Learning',
  LANDING_DICTIONARY: 'Từ điển Việt-Anh',
  LANDING_GRAMMAR: 'Ngữ pháp tiếng Anh',
  LANDING_GRAMMAR_IELTS_BLOG: 'Ngữ pháp IELTS',
  LANDING_VOCAB_IELTS_BLOG: 'Từ vựng IELTS',
  LANDING_BOOK_REVIEW: 'Tài Liệu IELTS',
  VIEW_TEST_QUESTION: 'Đề',
  DO_TEST: 'Do test',
  VIEW_SOLUTION: 'Đáp án & giải thích',
  VIEW_VOCAB: 'Từ vựng IELTS',

  LANDING_HOME: 'Landing Home',
  VIEW_TRANSCRIPT: 'Xem giải thích',

  BOOK: 'Sách',

  VIEW_BLOG: 'Xem Blog',
  DO_DICTATION: 'Chép chính tả',
  SPEAKING_IELTS_BLOG: 'Blog IELTS speaking',
  WRITING_IELTS_BLOG: 'Blog IELTS writing',

  WRITING_SAMPLE_DETAIL: 'Chi tiết bài mẫu',
  SPEAKING_SAMPLE_DETAIL: 'Chi tiết bài mẫu speaking',

  VOCAB_IELTS_BLOG: 'Blog từ vựng',
  GRAMMAR_IELTS_BLOG: 'Blog ngữ pháp',

  DICTIONARY_DETAIL: 'Chi tiết từ điển',

  // K12 Detail
  K12_UNIT_DETAIL: 'K12_UNIT_DETAIL',
  K12_EXAM_DETAIL: 'K12_EXAM_DETAIL',
  K12_SHARING_BLOG_DETAIL: 'K12_SHARING_BLOG_DETAIL',

  // K12 Landing
  LANDING_K12_GRADE_6: 'LANDING_K12_GRADE_6',
  LANDING_K12_GRADE_7: 'LANDING_K12_GRADE_7',
  LANDING_K12_GRADE_8: 'LANDING_K12_GRADE_8',
  LANDING_K12_GRADE_9: 'LANDING_K12_GRADE_9',
  LANDING_K12_GRADE_10: 'LANDING_K12_GRADE_10',
  LANDING_K12_GRADE_11: 'LANDING_K12_GRADE_11',
  LANDING_K12_GRADE_12: 'LANDING_K12_GRADE_12',

  LANDING_K12_GRADE_6_NEW: 'LANDING_K12_GRADE_6_NEW',
  LANDING_K12_GRADE_7_NEW: 'LANDING_K12_GRADE_7_NEW',
  LANDING_K12_GRADE_8_NEW: 'LANDING_K12_GRADE_8_NEW',
  LANDING_K12_GRADE_9_NEW: 'LANDING_K12_GRADE_9_NEW',
  LANDING_K12_GRADE_10_NEW: 'LANDING_K12_GRADE_10_NEW',
  LANDING_K12_GRADE_11_NEW: 'LANDING_K12_GRADE_11_NEW',
  LANDING_K12_GRADE_12_NEW: 'LANDING_K12_GRADE_12_NEW',
  LANDING_K12_HIGH_SCHOOL_EXAM: 'LANDING_K12_HIGH_SCHOOL_EXAM',
  LANDING_K12_COLLEGE_EXAM: 'LANDING_K12_COLLEGE_EXAM',
  K12_HIGH_SCHOOL_EXAM: 'K12_HIGH_SCHOOL_EXAM',
  K12_COLLEGE_EXAM: 'K12_COLLEGE_EXAM',
  LAND_K12_EXAM_BOOK: 'LAND_K12_EXAM_BOOK',
};

export const getPageDisplayNameByKey = (key) => {
  return values[key] || key;
};
