import { apis } from '@doltech/core/lib/api/api.config';
import { truncate } from 'lodash';
import { useQuery } from 'react-query';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export const SEARCH_QUERY_MAX_LENGTH = 100;

const fetchResult = async (query: string, page: number) => {
  if (!query) {
    return undefined;
  }
  const response = await apis.publicSearchTransform.get(
    apiPublicUrls.searchTransform.SEARCH_RESULT,
    {
      query,
      size: 3,
      page,
    }
  );
  return response.data;
};

const fetchSuggestion = async (query: string) => {
  if (!query) {
    return undefined;
  }
  const response = await apis.publicSearchTransform.get(
    apiPublicUrls.searchTransform.SEARCH_SUGGESTION,
    {
      query,
      size: 7,
    }
  );
  return response.data;
};

export const trackingClick = (body) =>
  apis.publicSearchTransform.post(apiPublicUrls.searchTransform.CLICK_TRACKING, body);

export const fetchResultApi = async ({ queryKey }) => {
  const [, params] = queryKey;
  if (params.query) {
    params.query = truncate(params.query, { length: SEARCH_QUERY_MAX_LENGTH, omission: '' });
  }

  const { data } = await apis.publicSearchTransform.get(
    apiPublicUrls.searchTransform.SEARCH_RESULT,
    params
  );

  return data;
};

export const fetchPopularResult = () =>
  apis.publicSearchTransform.get(apiPublicUrls.searchTransform.POPULAR_RESULT);

export const useSearchWithResultAndSuggestion = (query, page) => {
  const result = useQuery([query, page], () => fetchResult(query, page));
  const suggestion = useQuery([query], () => fetchSuggestion(query));
  return [result, suggestion];
};

const fetDicResult = async (query: string, page: number, size = 6) => {
  if (!query) {
    return undefined;
  }
  const response = await apis.publicSearchTransform.get(
    apiPublicUrls.searchTransform.DICTIONARY_SEARCH_RESULT,
    {
      query,
      size,
      page,
    }
  );
  return response.data;
};

const elasticSearchSuggestion = async (query) => {
  const { data } = await apis.publicSearchTransform.get(
    apiPublicUrls.searchTransform.GET_LANDING_SUGGESTION_ELASTIC,
    { query, size: 5 }
  );

  return data;
};

export const useFetchDictionaryDetail = ({ query, page, size }) => {
  return useQuery<any>([query, page, size], () => fetDicResult(query, page, size));
};
export const useFetchK12Suggestion = (query) => {
  const result = useQuery([query], () => elasticSearchSuggestion(query));
  return { result };
};
