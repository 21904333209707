import { getLandingUrlFromPath } from '@doltech/utils/lib/url';
import { NextSeo, NextSeoProps } from 'next-seo';
import * as React from 'react';

interface SeoHeadProps extends NextSeoProps {}

export const SeoHead = (props: SeoHeadProps) => {
  const { openGraph, twitter, ...rest } = props;
  return (
    <NextSeo
      title="tuhocielts.dolenglish.vn - Học Tiếng Anh Free - Chất lượng Premium"
      description="Nền tảng học IELTS nội dung Free chất lượng Premium"
      openGraph={{
        title: 'tuhocielts.dolenglish.vn - Học Tiếng Anh Free - Chất lượng Premium',
        description: 'Nền tảng học IELTS nội dung Free chất lượng Premium',
        images: [{ url: getLandingUrlFromPath('img/welcome-to-dol.png') }],
        type: 'website',
        ...openGraph,
      }}
      twitter={{
        cardType: 'summary_large_image',
        ...twitter,
      }}
      {...rest}
    />
  );
};

export const SeoHeadGrammar = (props: SeoHeadProps) => {
  const { openGraph, twitter, ...rest } = props;
  return (
    <NextSeo
      title="grammar.dolenglish.vn - DOL Grammar"
      description="DOL Grammar cung cấp đầy đủ kiến thức về lý thuyết, bài tập, tài liệu và công cụ kiểm tra ngữ pháp tiếng Anh"
      openGraph={{
        title: 'grammar.dolenglish.vn - DOL Grammar',
        description: 'DOL Grammar cung cấp đầy đủ kiến thức về lý thuyết, bài tập, tài liệu và công cụ kiểm tra ngữ pháp tiếng Anh',
        images: [{ url: 'https://media.dolenglish.vn//PUBLIC/MEDIA/logo-dol-grammar.png' }],
        type: 'article',
        ...openGraph,
      }}
      twitter={{
        cardType: 'summary_large_image',
        ...twitter,
      }}
      {...rest}
    />
  );
};
