import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { getPageDisplayNameByKey } from '@doltech/utils/lib/enum/PageDisplayNameEnum';
import * as React from 'react';
import styled from 'styled-components';
import { Grid } from 'styled-css-grid';

import { Button } from '../../ButtonV2/ButtonV2';
import { colorsV2 } from '../../colors-v2';
import { Typography } from '../../DesignSystemV2/Typography';
import { useLandingSharedDataStore } from '../../Layout/Landing/model/landing-shared-data.hook';
import { Link } from '../../Link';
import { SubText } from '../../Typography';

const DivWrapper = styled(Grid)`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding-bottom: 90px;
`;

const KEEP_IN_SEARCH = [
  PageTypeEnum.LANDING_FULL_TEST,
  PageTypeEnum.LANDING_READING_PRACTICE_TEST,
  PageTypeEnum.LANDING_LISTENING_PRACTICE_TEST,
  PageTypeEnum.LANDING_DAILY_LEARNING_NEWS,
  PageTypeEnum.LANDING_DICTATION,
];

export const ExploreDolContent = ({ isDefault = true }) => {
  const store = useLandingSharedDataStore();
  const totalUrl = store.menuItems.filter((item) => KEEP_IN_SEARCH.includes(item.pageType as any));
  return (
    <>
      <SubText>Explore DOL’s Content Types</SubText>
      <DivWrapper columnGap="16px" rowGap="12px">
        {totalUrl.map((item, index) => (
          <Link href={item.url} key={index}>
            <Button.Secondary key={index} variant={isDefault ? 'outline' : 'white'}>
              <Typography.Text style={{ color: colorsV2.black100 }} variant="semi-bold/14">
                {getPageDisplayNameByKey(item.pageType)}
              </Typography.Text>
            </Button.Secondary>
          </Link>
        ))}
      </DivWrapper>
    </>
  );
};
